@use 'src/styles/variables' as variables;

.container {
	margin: 0 auto;
	margin-top: 4.6rem;
	width: 100%;
	max-width: var(--global-max-width);

	@media (max-width: variables.$breakpoint-lg) {
		margin-top: 4.25rem;
	}
}

.menuV3 {
	margin-top: 0;
}
