@use 'src/styles/functions' as functions;
@use 'src/styles/variables' as variables;

.container {
	display: flex;
	position: relative;
	justify-content: space-between;
	z-index: var(--z-index-breadcrumbs);
	margin-bottom: -50px;
	padding: var(--space-inset-xl) var(--space-inset-3xl) 0 var(--space-inset-3xl);
	width: 80%;
	min-height: 50px;

	@media (max-width: variables.$breakpoint-max) {
		&.hasSubMenu {
			padding-left: var(--space-inset-lg);
		}
	}

	.breadcrumbs {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style-type: none;

		.breadcrumb {
			display: inline;
			margin-right: 5px;
			font-size: functions.rem(14px);

			.breadcrumbLink {
				display: flex;
				align-items: center;
				float: left;
				font-weight: var(--font-weight-light);

				.chevron {
					margin: 0 0.25rem 0 0.5rem;
					width: functions.rem(9.5px);

					@media (max-width: variables.$breakpoint-md) {
						margin: 0 0.5rem;
						min-width: 0.5rem;
						font-size: 1rem;
					}
				}
			}

			.currentPage {
				font-weight: var(--font-weight-bold);
			}

			.homeLink {
				align-content: center;
			}

			.homeLink::before {
				content: '';
			}
		}
	}
}

@media (max-width: variables.$breakpoint-lg) {
	.container {
		display: none;
	}
}
